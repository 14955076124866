<template>
  <div class="card mb-2">
    <div class="card-body">
      <div class="row">
        <div class="col-lg-6">
          <span class="badge text-muted ps-3 mb-2 text-uppercase"
            >{{ $t("index_page_stats.blockchain_data") }}
          </span>
          <div class="row small g-2">
            <div class="col-lg-6">
              <div class="d-flex p-0 border-0 text-decoration-none">
                <div class="w-50 py-1 px-3 text-muted">
                  {{ $t("index_page_stats.total_accounts") }}
                </div>
                <div class="w-50 py-1 px-3 fw-bold font-monospace text-end">
                  -
                </div>
              </div>
              <div class="d-flex p-0 border-0 text-decoration-none">
                <div class="w-50 py-1 px-3 text-muted">
                  {{ $t("index_page_stats.total_assets") }}
                </div>
                <div class="w-50 py-1 px-3 fw-bold font-monospace text-end">
                  -
                </div>
              </div>
              <div class="d-flex p-0 border-0 text-decoration-none">
                <div class="w-50 py-1 px-3 text-muted">
                  {{ $t("index_page_stats.xlm_circulation") }}
                </div>
                <div class="w-50 py-1 px-3 fw-bold font-monospace text-end">
                  -
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="d-flex p-0 border-0 text-decoration-none">
                <div class="w-50 py-1 px-3 text-muted">
                  {{ $t("index_page_stats.tps_24h") }}
                </div>
                <div class="w-50 py-1 px-3 fw-bold font-monospace text-end">
                  -
                </div>
              </div>
              <div class="d-flex p-0 border-0 text-decoration-none">
                <div class="w-75 py-1 px-3 text-muted">
                  {{ $t("index_page_stats.average_ledger_time") }}
                </div>
                <div class="w-25 py-1 px-3 fw-bold font-monospace text-end">
                  -
                </div>
              </div>
              <div class="d-flex p-0 border-0 text-decoration-none">
                <div class="w-50 py-1 px-3 text-muted">
                  {{ $t("index_page_stats.protocol_version") }}
                </div>
                <div class="w-50 py-1 px-3 fw-bold font-monospace text-end">
                  -
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <span class="badge text-muted ps-3 mb-2 text-uppercase">{{
            $t("index_page_stats.ledger_stats")
          }}</span>
          <div class="row small g-2">
            <div class="col-lg-6">
              <div class="d-flex p-0 border-0 text-decoration-none">
                <div class="w-50 py-1 px-3 text-muted">
                  {{ $t("index_page_stats.ledgers") }}
                </div>
                <div class="w-50 py-1 px-3 fw-bold font-monospace text-end">
                  -
                </div>
              </div>
              <div class="d-flex p-0 border-0 text-decoration-none">
                <div class="w-50 py-1 px-3 text-muted">
                  {{ $t("index_page_stats.transactions") }}
                </div>
                <div class="w-50 py-1 px-3 fw-bold font-monospace text-end">
                  -
                </div>
              </div>
              <div class="d-flex p-0 border-0 text-decoration-none">
                <div class="w-50 py-1 px-3 text-muted">
                  {{ $t("index_page_stats.operations") }}
                </div>
                <div class="w-50 py-1 px-3 fw-bold font-monospace text-end">
                  -
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="d-flex p-0 border-0 text-decoration-none">
                <div class="w-50 py-1 px-3 text-muted">
                  {{ $t("index_page_stats.payments") }}
                </div>
                <div class="w-50 py-1 px-3 fw-bold font-monospace text-end">
                  -
                </div>
              </div>
              <div class="d-flex p-0 border-0 text-decoration-none">
                <div class="w-50 py-1 px-3 text-muted">
                  {{ $t("index_page_stats.dex_trades") }}
                </div>
                <div class="w-50 py-1 px-3 fw-bold font-monospace text-end">
                  -
                </div>
              </div>
              <div class="d-flex p-0 border-0 text-decoration-none">
                <div class="w-50 py-1 px-3 text-muted">
                  {{ $t("index_page_stats.dex_volume") }}
                </div>
                <div
                  class="
                    w-50
                    py-1
                    px-3
                    fw-bold
                    font-monospace
                    d-flex
                    justify-content-between
                  "
                >
                  <span></span>
                  <span>-</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "BlockchainStatsSkeleton",
  components: {},
  setup() {
    return {};
  },
});
</script>
