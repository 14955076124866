<template>
  <div class="items-list"> 
      <ledger-summary-skeleton v-if="loading" :rows="rows" />
      <error v-else-if="error" />
      <div
        v-for="ledger in ledgers"
        v-else
        :key="ledger.id"
        class="list-group mb-2"
      >
        <div class="list-group-item">
          <div class="row">
            <div class="col-lg-3">
              <div class="d-flex align-items-center">
                <router-link
                  v-tooltip
                  :to="{
                    name: 'LedgerDetailsRoute',
                    params: {
                      ledger_sequence: ledger.sequence,
                    },
                  }"
                  class="flex-shrink-0 bg-primary bg-opacity-25 rounded-3 d-flex"
                  style="height: 36px; width: 36px"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  :data-bs-original-title="$t('ledger_summary.ledger')"
                >
                  <i
                    class="
                      bi bi-card-list
                      fs-5
                      align-self-center
                      mx-auto
                      text-primary
                    "
                  ></i>
                </router-link>
                <div class="flex-grow-1 ms-3">
                  <router-link
                    v-tooltip
                    :to="{
                      name: 'LedgerDetailsRoute',
                      params: {
                        ledger_sequence: ledger.sequence,
                      },
                    }"
                    class="
                      fw-bold
                      text-reset
                      text-decoration-none
                      font-monospace
                      small
                    "
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    :data-bs-original-title="$t('ledger_summary.ledger_seq_number')"
                  >
                    {{ ledger.sequence }}
                  </router-link>
                  <p class="small mb-0 font-monospace" style="max-width: 200px">
                    <router-link
                      v-tooltip
                      v-middle-ellipsis="{ size: 8, text: ledger.hash }"
                      :to="{
                        name: 'LedgerDetailsRoute',
                        params: {
                          ledger_sequence: ledger.sequence,
                        },
                      }"
                      class="text-muted text-decoration-none"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      :data-bs-original-title="$t('ledger_summary.ledger_hash')"
                    >
                    </router-link>
                  </p>
                </div>
                <div class="flex-shrink-0">
                  <span
                    v-tooltip
                    class="
                      badge
                      bg-primary bg-opacity-25
                      text-primary
                      align-self-center
                    "
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title=""
                    :data-bs-original-title="$t('ledger_summary.protocol_version')"
                  >
                    {{ ledger.protocol_version }}</span
                  >
                </div>
              </div>
            </div>
            <div class="col-lg-6 d-flex">
              <div class="align-self-center w-100">
                <div
                  class="d-flex justify-content-between text-muted font-monospace"
                >
                  <span class="small"> {{ $t("ledger_summary.0") }}</span>
                  <span
                    v-tooltip
                    class="small"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    :data-bs-original-title="$t('ledger_summary.operations')"
                  >
                    {{ ledger.operation_count }}</span
                  >
                  <span
                    v-tooltip
                    class="small"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    :data-bs-original-title="$t('ledger_summary.max_ops_ledger')"
                  >
                    {{ ledger.max_tx_set_size }}</span
                  >
                </div>
                <div class="progress" style="height: 7px">
                  <div
                    v-tooltip
                    class="progress-bar"
                    role="progressbar"
                    :style="`width:${progress[ledger.sequence]}%`"
                    :aria-valuenow="progress[ledger.sequence]"
                    aria-valuemin="0"
                    aria-valuemax="100"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    :data-bs-original-title="
                      progress[ledger.sequence] + $t('ledger_summary.capacity')
                    "
                  ></div>
                </div>
              </div>
            </div>
            <div class="col-lg-3 d-flex justify-content-between">
              <div>
                <b class="d-block small"
                  ><span
                    v-tooltip
                    class="
                      badge
                      bg-success bg-opacity-25
                      text-success
                      align-self-center
                    "
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title=""
                    :data-bs-original-title="$t('ledger_summary.successful_txn')"
                    ><i class="bi bi-check-circle-fill"></i>
                    {{ ledger.successful_transaction_count }}</span
                  ></b
                >
                <small class="d-block text-muted"
                  ><span
                    v-tooltip
                    class="
                      badge
                      bg-danger bg-opacity-25
                      text-danger
                      align-self-center
                    "
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title=""
                    :data-bs-original-title="$t('ledger_summary.failed_txns')"
                    ><i class="bi bi-x-circle-fill"></i>
                    {{ ledger.failed_transaction_count }}</span
                  ></small
                >
              </div>
              <div class="text-end">
                <b class="d-block small">closed {{
                  formatDateElapsedTime(ledger.closed_at)
                }}</b>
                <small class="d-block text-muted">{{
                  formatDate(ledger.closed_at)
                }}</small>
              </div>
            </div>
          </div>
        </div>
      </div>
</div>
</template>

<script>
import { defineComponent, computed } from "vue";
import { useStore } from "vuex";
import { formatDateElapsedTime, formatDate } from "@/utils/date.js";
import Error from "@/components/Error.vue";
import LedgerSummarySkeleton from "@/components/skeletons/summaries/LedgerSummarySkeleton.vue";

export default defineComponent({
  name: "LedgerSummary",
  components: {
    Error,
    LedgerSummarySkeleton,
  },
  props: {
    rows: Number,
  },
  setup() {
    const store = useStore();

    const ledgers = computed(() => {
      return store.getters["ledgers/getLatestLedgers"];
    });
    const progress = computed(() => {
      let progressArr = {};
      ledgers.value.forEach((ledger) => {
        progressArr[ledger.sequence] =
          (100 * ledger.operation_count) / ledger.max_tx_set_size;
      });
      return progressArr;
    });

    const loading = computed(() => store.getters["ledgers/getLoadingStatus"]);
    const error = computed(() => store.getters["ledgers/getError"]);
    return {
      store,
      formatDateElapsedTime,
      formatDate,
      ledgers,
      loading,
      error,
      progress,
    };
  },
});
</script>

<style scoped>
</style>
