<template>
  <div class="d-lg-none">
    <div
      v-for="row in rows"
      :key="row"
      class="list-group my-2 mt-lg-0 d-xl"
      style="height: 133px"
    >
      <div class="list-group-item h-100"></div>
    </div>
  </div>
  <div class="d-none d-lg-block">
    <div
      v-for="row in rows"
      :key="row"
      class="list-group my-2 mt-lg-0 d-xl"
      style="height: 64px"
    >
      <div class="list-group-item h-100"></div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "LedgerSummarySkeleton",
  components: {},
  props: {
    rows: Number,
  },
  setup() {
    return {};
  },
});
</script>
