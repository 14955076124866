<template>
    <section class="mt-2 mb-2"> 
      <div class="bg-body border border-primary rounded px-3 py-2">
        <div class="d-flex flex-row gap-3 align-items-center">
          <!-- icon -->
          <i class="bi bi-question-circle text-primary fs-2"></i>
          <!-- text -->
          <div class="d-flex flex-column justify-content-center">
            <div class="fw-bold">Resource not found.</div>
            <div class="small">
              <p>{{ message }} Error code {{errorCode}}.</p>
              <p v-if="errorCode == 404">{{t('error.no_identifier')}}</p>
                <p v-for="index, network in NetworksEnum" :key="index">
                <a v-if="index < 2" target="_blank" :href="linkParse(network, route)">{{linkParse(network, route)}}</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
</template>

<script>
import { computed, defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import { NetworksEnum } from "@/store/modules/settings/state";

export default defineComponent({
  name: "Error",
  props: {
    customMsg: String,
    errorMsg: Object,
  },
  setup(props) {
    const { t } = useI18n();
    const route = useRoute();
    const errorCode = computed(() => {
      if(props.errorMsg && props.errorMsg.toString().toLowerCase().includes('not found')){
         return 404;
      }
      return 404
    });

    const linkParse = (network, route) => {
        let networkSubdomain = network + '.';
        if(networkSubdomain == 'liveNet.'){
            networkSubdomain = '';
        }
        return `https://${networkSubdomain.toLowerCase()}stellarchain.io${route.fullPath}`
    }

    const message = computed(() => {
      if (props.customMsg) {
        return props.customMsg;
      }
      return t("error.could_not_fetch_data");
    });
    return { t, message, errorCode, route, NetworksEnum, linkParse };
  },
});
</script>

<style>
</style>
