/* eslint-disable vue/require-default-prop */
<template>
  <div class="card">
    <div
      class="
        card-header
        border-bottom-0
        text-uppercase
        d-flex
        bg-body
        justify-content-between
      "
    >
      <span class="badge text-muted ps-0">
        <template v-if="isPrice"><i class="bi bi-coin"></i> {{ $t("charts.price") }}</template>
        <template v-else>
            <i class="bi bi-list-ul"></i>
            {{$t(`price_charts.${graph.replace("gf_", "")}`)}}
        </template>
      </span>
      <div>
        <a
          ><span
            class="badge cp me-1"
            :class="aggregation === 'd' ? 'bg-primary' : 'bg-secondary-subtle text-muted'"
            @click="changeAgg('d')"
          >
            {{ $t("charts.d") }}</span
          ></a
        >
        <a 
          ><span
            class="badge cp me-1"
            :class="aggregation === 'w' ? 'bg-primary' : 'bg-secondary-subtle text-muted'"
            @click="changeAgg('w')"
          >
            {{ $t("charts.w") }}</span
          ></a
        >
        <a><span
            class="badge cp me-1"
            :class="aggregation === 'm' ? 'bg-primary' : 'bg-secondary-subtle text-muted'"
            @click="changeAgg('m')"
          >
            {{ $t("charts.m") }}</span
          ></a
        >
        <a
        ><span
            class="badge cp"
            :class="aggregation === 'y' ? 'bg-primary' : 'bg-secondary-subtle text-muted'"
            @click="changeAgg('y')"
            >{{ $t("charts.y") }}</span
          ></a
        >
      </div>
    </div>
    <div class="card-body pt-0">
      <v-chart
        :option="option"
        :update-options="{ replaceMerge: ['xAxis', 'yAxis', 'series'] }"
        style="
          -webkit-tap-highlight-color: transparent;
          user-select: none;
          position: relative;
          height: 128px;
        "
      >
      </v-chart>
    </div>
  </div>
</template>

<script>
import { defineComponent, computed, ref, watch } from "vue";
import { useStore } from "vuex";
import { use } from "echarts/core";
import {
  TooltipComponent,
  ToolboxComponent,
  GridComponent,
} from "echarts/components";
import { CanvasRenderer } from "echarts/renderers";
import { LineChart, BarChart } from "echarts/charts";
import 'echarts/lib/component/dataZoom'
import VChart from "vue-echarts";
import { useI18n } from "vue-i18n";
import { valueFormatter } from "@/utils/chart.js";
use([
  ToolboxComponent,
  TooltipComponent,
  CanvasRenderer,
  GridComponent,
  LineChart,
  BarChart,
]);
export default defineComponent({
  name: "FinancialStatsChart",
  components: { VChart },
  props: {
    isPrice: Boolean,
    graph: String,
    margins: Object,
  },
  setup(props) {
    const store = useStore();
    const aggregation = ref("d");
    const currency = "usd";
    const { t } = useI18n();
    const formatter = valueFormatter(props.graph);

    const fetchData = (graph, aggregation) => {
      if (props.isPrice) {
        store.dispatch("charts/fetchFinancialData", {
          aggregation,
          currency,
          graph,
        });
      } else {
        store.dispatch("charts/fetchBlockchainData", {
          graph,
          aggregation,
        });
      }
    };
    fetchData(props.graph, aggregation.value);
    watch(aggregation, (aggregation) => {
      fetchData(props.graph, aggregation);
    });
    watch(
      () => props.graph,
      (graph) => {
        fetchData(graph, aggregation);
      }
    );

    const graphData = computed(() => {
      if (props.isPrice && store.getters['charts/getFinancialData']) {
        return store.getters["charts/getFinancialData"][props.graph]?.map((item) => [
          item.date,
          item[props.graph]
        ]);
      } else if(store.getters['charts/getFinancialData']){
        return store.getters["charts/getBlockchainData"][props.graph]?.map((item) => [
          item.date,
          item[props.graph],
        ]);
      }
      return [] 
    });

    const option = computed(() => ({
      toolbox: {
        show: false,
      },
      grid: [props.margins],
      tooltip: {
        trigger: "axis",
      },
      xAxis: {
        type: "time",
        axisLine: {
          show: false, // Hide full Line
        },
        splitLine: {
          show: false,
        },
      },
      yAxis: {
        type: "value",
        splitNumber: 3,
        scale: true,
        axisLabel: {
          formatter: function (value) {
            return formatter(value);
          },
        },
        axisLine: {
          show: false, // Hide full Line
        },
        xisTick: {
          show: false, // Hide Ticks,
        },
        splitLine: {
          show: false,
        },
      },
      dataZoom: [
        {
          type: 'inside',
          show: true,
          height: 15,
          bottom: 14,
          start: 85,
          end: 100,
        }
      ],
      series: [
        {
          type: "bar",
          showSymbol: false,
          data: graphData.value,
          areaStyle: {},
          name: props.isPrice
            ? t(`price_charts.${props.graph.replace("gf_", "")}`)
            : t(`charts.${props.graph.replace("gf_", "")}`),
        },
      ],
    }));

    const changeAgg = (agg) => {
      aggregation.value = agg;
    };

    return {
      option,
      store,
      aggregation,
      currency,
      changeAgg,
    };
  },
});
</script>

    
