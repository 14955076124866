<template>
  <div class="card-group text-center mb-2">
    <div class="card">
      <div class="card-body">
        <div class="d-flex">
          <div
            class="flex-shrink-0 border border-2 rounded-circle d-flex"
            style="height: 44px; width: 44px"
          >
            <i
              class="
                bi bi-hash
                fs-4
                rounded-circle
                align-self-center
                mx-auto
                text-primary
              "
            ></i>
          </div>
          <div class="flex-grow-1 ms-3">
            <div class="float-start text-start">
              <span class="text-black fw-bold mb-0 d-block">-</span>
              <span
                class="text-muted d-block text-uppercase"
                style="font-size: 12px"
                >{{ $t("index_page_stats.rank") }}</span
              >
            </div>
          </div>
          <div class="flex-shrink-0 d-flex"></div>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-body text-decoration-none">
        <div class="d-flex">
          <div
            class="flex-shrink-0 border border-2 rounded-circle d-flex"
            style="height: 44px; width: 44px"
          >
            <i
              class="
                bi bi-coin
                fs-4
                rounded-circle
                align-self-center
                mx-auto
                text-primary
              "
            ></i>
          </div>
          <div class="flex-grow-1 ms-3">
            <div class="float-start text-start">
              <span class="text-black fw-bold mb-0 d-block">-</span>
              <span
                class="text-muted d-block text-uppercase"
                style="font-size: 12px"
                >{{ $t("index_page_stats.price") }}</span
              >
            </div>
          </div>
          <div class="flex-shrink-0 d-flex"></div>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-body text-decoration-none">
        <div class="d-flex">
          <div
            class="flex-shrink-0 border border-2 rounded-circle d-flex"
            style="height: 44px; width: 44px"
          >
            <i
              class="
                bi bi-piggy-bank
                fs-4
                rounded-circle
                align-self-center
                mx-auto
                text-primary
              "
            ></i>
          </div>
          <div class="flex-grow-1 ms-3">
            <div class="float-start text-start">
              <span class="text-black fw-bold mb-0 d-block">-</span>
              <span
                class="text-muted d-block text-uppercase"
                style="font-size: 12px"
                >{{ $t("index_page_stats.market_cap") }}</span
              >
            </div>
          </div>
          <div class="flex-shrink-0 d-flex"></div>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-body text-decoration-none">
        <div class="d-flex">
          <div
            class="flex-shrink-0 border border-2 rounded-circle d-flex"
            style="height: 44px; width: 44px"
          >
            <i
              class="
                bi bi-currency-exchange
                fs-4
                rounded-circle
                align-self-center
                mx-auto
                text-primary
              "
            ></i>
          </div>
          <div class="flex-grow-1 ms-3">
            <div class="float-start text-start">
              <span class="text-black fw-bold mb-0 d-block">-</span>
              <span
                class="text-muted d-block text-uppercase"
                style="font-size: 12px"
                >{{ $t("index_page_stats.volume_24h") }}</span
              >
            </div>
          </div>
          <div class="flex-shrink-0 d-flex"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "NativeAssetStatsSkeleton",
  components: {},
  setup() {
    return {};
  },
});
</script>
