<template>
  <native-asset-stats-skeleton v-if="assetStats.loading" />
  <native-asset-stats-skeleton v-else-if="assetStats.error" />
  <div v-else class="card-group text-center mb-2">
    <div class="card">
      <div class="card-body">
        <div class="d-flex">
          <div
            class="flex-shrink-0 border border-2 rounded-circle d-flex"
            style="height: 44px; width: 44px"
          >
            <i
              class="
                bi bi-hash
                fs-4
                rounded-circle
                align-self-center
                mx-auto
                text-primary
              "
            ></i>
          </div>
          <div class="flex-grow-1 ms-3">
            <div class="float-start text-start">
              <span class=" fw-bold mb-0 d-block">{{
                assetStats.data.rank
              }}</span>
              <span
                class="text-muted d-block text-uppercase"
                style="font-size: 12px"
                >{{ $t("index_page_stats.rank") }}</span
              >
            </div>
          </div>
          <div class="flex-shrink-0 d-flex">
            <span
              class="badge bg-opacity-25 align-self-center"
              :class="{
                'text-danger bg-danger': assetStats.data.rank_change < 0,
                'text-secondary bg-secondary':
                  assetStats.data.rank_change === 0,
                'text-success bg-success': assetStats.data.rank_change > 0,
              }"
              ><i
                class="bi"
                :class="{
                  'bi-caret-down-fill': assetStats.data.rank_change < 0,
                  'bi-caret-right-fill': assetStats.data.rank_change === 0,
                  'bi-caret-up-fill': assetStats.data.rank_change > 0,
                }"
              ></i>
              {{ assetStats.data.rank_change }}</span
            >
          </div>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-body text-decoration-none">
        <div class="d-flex">
          <div
            class="flex-shrink-0 border border-2 rounded-circle d-flex"
            style="height: 44px; width: 44px"
          >
            <i
              class="
                bi bi-coin
                fs-4
                rounded-circle
                align-self-center
                mx-auto
                text-primary
              "
            ></i>
          </div>
          <div class="flex-grow-1 ms-3">
            <div class="float-start text-start">
              <router-link class="text-decoration-none text-reset fw-bold mb-0 d-block" to="price/market_price">
                {{"$" + assetStats.data.price_usd}}
              </router-link>
              <span
                class="text-muted d-block text-uppercase"
                style="font-size: 12px">
                {{ $t("index_page_stats.price") }}
              </span>
            </div>
          </div>
          <div class="flex-shrink-0 d-flex">
            <span
              class="badge bg-opacity-25 align-self-center"
              :class="{
                'text-danger bg-danger': assetStats.data.price_usd_change < 0,
                'text-secondary bg-secondary':
                  assetStats.data.price_usd_change === 0,
                'text-success bg-success': assetStats.data.price_usd_change > 0,
              }"
              ><i
                class="bi"
                :class="{
                  'bi-caret-down-fill': assetStats.data.price_usd_change < 0,
                  'bi-caret-right-fill': assetStats.data.price_usd_change === 0,
                  'bi-caret-up-fill': assetStats.data.price_usd_change > 0,
                }"
              ></i>
              {{ assetStats.data.price_usd_change }}%</span
            >
          </div>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-body text-decoration-none">
        <div class="d-flex">
          <div
            class="flex-shrink-0 border border-2 rounded-circle d-flex"
            style="height: 44px; width: 44px"
          >
            <i
              class="
                bi bi-piggy-bank
                fs-4
                rounded-circle
                align-self-center
                mx-auto
                text-primary
              "
            ></i>
          </div>
          <div class="flex-grow-1 ms-3">
            <div class="float-start text-start">
              <router-link class="text-decoration-none text-reset fw-bold mb-0 d-block" to="price/market_capitalization">
                ${{ assetStats.data.market_cap_usd.toLocaleString() }}
              </router-link>
              <span
                class="text-muted d-block text-uppercase"
                style="font-size: 12px">
                {{ $t("index_page_stats.market_cap") }}
              </span>
            </div>
          </div>
          <div class="flex-shrink-0 d-flex">
            <span
              class="badge bg-opacity-25 align-self-center"
              :class="{
                'text-danger bg-danger': assetStats.data.market_cap_change < 0,
                'text-secondary bg-secondary':
                  assetStats.data.market_cap_change === 0,
                'text-success bg-success':
                  assetStats.data.market_cap_change > 0,
              }"
              ><i
                class="bi"
                :class="{
                  'bi-caret-down-fill': assetStats.data.market_cap_change < 0,
                  'bi-caret-right-fill':
                    assetStats.data.market_cap_change === 0,
                  'bi-caret-up-fill': assetStats.data.market_cap_change > 0,
                }"
              ></i>
              {{ assetStats.data.market_cap_change }}%</span
            >
          </div>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-body text-decoration-none">
        <div class="d-flex">
          <div
            class="flex-shrink-0 border border-2 rounded-circle d-flex"
            style="height: 44px; width: 44px"
          >
            <i
              class="
                bi bi-currency-exchange
                fs-4
                rounded-circle
                align-self-center
                mx-auto
                text-primary
              "
            ></i>
          </div>
          <div class="flex-grow-1 ms-3">
            <div class="float-start text-start">
              <span class=" fw-bold mb-0 d-block"
                >${{ assetStats.data.volume_usd_24h.toLocaleString() }}</span
              >
              <span
                class="text-muted d-block text-uppercase"
                style="font-size: 12px"
                >{{ $t("index_page_stats.volume_24h") }}</span
              >
            </div>
          </div>
          <div class="flex-shrink-0 d-flex">
            <span
              class="badge bg-opacity-25 align-self-center"
              :class="{
                'text-danger bg-danger': assetStats.data.volume_change_24h < 0,
                'text-secondary bg-secondary':
                  assetStats.data.volume_change_24h === 0,
                'text-success bg-success':
                  assetStats.data.volume_change_24h > 0,
              }"
              ><i
                class="bi"
                :class="{
                  'bi-caret-down-fill': assetStats.data.volume_change_24h < 0,
                  'bi-caret-right-fill':
                    assetStats.data.volume_change_24h === 0,
                  'bi-caret-up-fill': assetStats.data.volume_change_24h > 0,
                }"
              ></i>
              {{ assetStats.data.volume_change_24h }}%</span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
  <blockchain-stats-skeleton v-if="blockchainStats.loading" />
  <blockchain-stats-skeleton v-else-if="blockchainStats.error" />
  <div v-else class="card mb-2">
    <div class="card-body">
      <div class="row">
        <div class="col-lg-6">
          <span class="badge text-muted ps-3 mb-2 text-uppercase"
            >{{ $t("index_page_stats.blockchain_data") }}
          </span>
          <div class="row small g-2">
            <div class="col-lg-6">
              <div class="d-flex p-0 border-0 text-decoration-none">
                <div class="w-50 py-1 px-3 text-muted">
                  {{ $t("index_page_stats.total_accounts") }}
                </div>
                <div class="w-50 py-1 px-3 fw-bold font-monospace text-end">
                  <router-link class="text-decoration-none text-reset" to="/analytics/total_accounts">
                    {{ blockchainStats.data.total_accounts }}
                  </router-link>
                </div>
              </div>
              <div class="d-flex p-0 border-0 text-decoration-none">
                <div class="w-50 py-1 px-3 text-muted">
                  {{ $t("index_page_stats.total_assets") }}
                </div>
                <div class="w-50 py-1 px-3 fw-bold font-monospace text-end">
                  <router-link class="text-decoration-none text-reset" to="/analytics/total_assets">
                    {{ blockchainStats.data.total_assets }}
                  </router-link>
                </div>
              </div>
              <div class="d-flex p-0 border-0 text-decoration-none">
                  <div class="w-50 py-1 px-3 text-muted">
                    {{ $t("index_page_stats.xlm_circulation") }}
                  </div>
                  <div class="w-50 py-1 px-3 fw-bold font-monospace text-end">
                    <router-link class="text-decoration-none text-reset" to="/price/circulating_supply">
                      {{ blockchainStats.data.xlm_circulation }}
                    </router-link>
                  </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="d-flex p-0 border-0 text-decoration-none">
                <div class="w-50 py-1 px-3 text-muted">
                  {{ $t("index_page_stats.tps_24h") }}
                </div>
                <div class="w-50 py-1 px-3 fw-bold font-monospace text-end">
                  <router-link class="text-decoration-none text-reset" to="/analytics/txns_per_second">
                    {{ blockchainStats.data["24h_tps"] }}
                  </router-link>
                </div>
              </div>
              <div class="d-flex p-0 border-0 text-decoration-none">
                <div class="w-75 py-1 px-3 text-muted">
                  {{ $t("index_page_stats.average_ledger_time") }}
                </div>
                <div class="w-25 py-1 px-3 fw-bold font-monospace text-end">
                  <router-link class="text-decoration-none text-reset" to="/charts/avg_ledger_time">
                    {{ blockchainStats.data.average_ledger_time }}
                  </router-link>
                </div>
              </div>
              <div class="d-flex p-0 border-0 text-decoration-none">
                <div class="w-50 py-1 px-3 text-muted">
                  {{ $t("index_page_stats.protocol_version") }}
                </div>
                <div class="w-50 py-1 px-3 fw-bold font-monospace text-end">
                  {{ blockchainStats.data.protocol_version }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <span class="badge text-muted ps-3 mb-2 text-uppercase">{{
            $t("index_page_stats.ledger_stats")
          }}</span>
          <div class="row small g-2">
            <div class="col-lg-6">
              <div class="d-flex p-0 border-0 text-decoration-none">
                <div class="w-50 py-1 px-3 text-muted">
                  {{ $t("index_page_stats.ledgers") }}
                </div>
                <div class="w-50 py-1 px-3 fw-bold font-monospace text-end">
                  <router-link class="text-decoration-none text-reset" to="/charts/24h_ledgers">
                    {{ blockchainStats.data["24h_ledgers"] }}
                  </router-link>
                </div>
              </div>
              <div class="d-flex p-0 border-0 text-decoration-none">
                <div class="w-50 py-1 px-3 text-muted">
                  {{ $t("index_page_stats.transactions") }}
                </div>
                <div class="w-50 py-1 px-3 fw-bold font-monospace text-end">
                  <router-link class="text-decoration-none text-reset" to="/charts/transactions">
                    {{ blockchainStats.data["24h_transactions"] }}
                  </router-link>
                </div>
              </div>
              <div class="d-flex p-0 border-0 text-decoration-none">
                <div class="w-50 py-1 px-3 text-muted">
                  {{ $t("index_page_stats.operations") }}
                </div>
                <div class="w-50 py-1 px-3 fw-bold font-monospace text-end">
                  <router-link class="text-decoration-none text-reset" to="/charts/total_no_ops">
                    {{ blockchainStats.data["24h_operations"] }}
                  </router-link>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="d-flex p-0 border-0 text-decoration-none">
                <div class="w-50 py-1 px-3 text-muted">
                  {{ $t("index_page_stats.payments") }}
                </div>
                <div class="w-50 py-1 px-3 fw-bold font-monospace text-end">
                  <router-link class="text-decoration-none text-reset" to="/price/24h_payments">
                    {{ blockchainStats.data["24h_payments"] }}
                  </router-link>
                </div>
              </div>
              <div class="d-flex p-0 border-0 text-decoration-none">
                <div class="w-50 py-1 px-3 text-muted">
                  {{ $t("index_page_stats.dex_trades") }}
                </div>
                <div class="w-50 py-1 px-3 fw-bold font-monospace text-end">
                  <router-link class="text-decoration-none text-reset" to="/price/24h_dex_trades">
                    {{ blockchainStats.data["24h_dex_trades"] }}
                  </router-link>
                </div>
              </div>
              <div class="d-flex p-0 border-0 text-decoration-none">
                <div class="w-50 py-1 px-3 text-muted">
                  {{ $t("index_page_stats.dex_volume") }}
                </div>
                <div
                  class="
                    w-50
                    py-1
                    px-3
                    fw-bold
                    font-monospace
                    d-flex
                    justify-content-between
                  "
                >
                  <span>$</span>
                  <router-link class="text-decoration-none text-reset" to="/price/exchange_trade_volume">
                    {{ blockchainStats.data["24h_dex_volume"] }}
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, computed } from "vue";
import { useStore } from "vuex";
import NativeAssetStatsSkeleton from "@/components/skeletons/stats/NativeAssetStatsSkeleton.vue";
import BlockchainStatsSkeleton from "@/components/skeletons/stats/BlockchainStatsSkeleton.vue";
export default defineComponent({
  name: "IndexPageStats",
  components: {
    NativeAssetStatsSkeleton,
    BlockchainStatsSkeleton,
  },
  setup() {
    const store = useStore();

    store.dispatch("analytics/fetchAssetStats");
    store.dispatch("analytics/fetchBlockchainStats");

    const assetStats = computed(() => store.getters["analytics/getAssetStats"]);
    const blockchainStats = computed(
      () => store.getters["analytics/getBlockchainStats"]
    );
    return { assetStats, blockchainStats };
  },
});
</script>
