<template>
  <div class="row pt-5">
    <div class="col-lg-7 mx-auto py-4 text-center">
        <img 
           :src="require(darkMode ? '@/assets/images/logo-white.png' : '@/assets/images/logo.png')"
           style="height: auto;width: 350px" alt="Stellar Blockhain Explorer"
        />
        <h1 class="h5 mt-2 mb-1 text-tertiary" style="font-weight: lighter;font-family: system-ui;">
            StellarChain Explorer: Your Stellar Blockchain Discovery Tool
        </h1>
      <div class="input-group py-4">
        <search />
      </div>
    </div>
    <div class="text-center">
      <img src="@/assets/images/bg-homepage.png" class="img-fluid" alt="Stellar Assets Cryptocurrency"/>
    </div>
  </div>
  <index-page-stats />
  <div class="row g-2">
    <div class="col-lg-12">
      <front-page-chart
        graph="gf_market_price"
        :margins="chartInfo.margins"
        :class="chartInfo.class"
        :is-price="true"
      />
    </div>
    <div class="col-lg-6">
      <front-page-chart
        graph="gf_total_no_ops"
        :margins="chartInfo.margins"
        :class="chartInfo.class"
        :is-price="false"
      />
    </div>
    <div class="col-lg-6">
      <front-page-chart
        graph="gf_transactions"
        :margins="chartInfo.margins"
        :class="chartInfo.class"
        :is-price="false"
      />
    </div>
  </div>
  <div class="card bg-transparent border-0 mt-2">
      <div class="card-header rounded mb-1 bg-body border">
        <span class="badge text-muted text-uppercase">
            <i class="bi bi-list-ul"></i>
            Latest ledgers
        </span>
       </div>
      <ledger-summary :rows="5" />
  </div>
  <div class="card">
    <div class="card-header bg-body text-uppercase">
        <small class="badge text-muted">
            <i class="bi bi-list-ul"></i>
            Changelog
        </small>
    </div>
    <div class="card-body small changelog d-flex">
        <carousel :items-to-show="4" snap-align="start">
            <slide key="6">
                <div class="card-version w-100">
                    <b class="text-muted">Version 3.16</b>
                    <ul class="small list-unstyled">
                        <li><i class="bi bi-arrow-right-short"></i>
                        Release Soroban main net smart contracts.
                        </li>
                        <li><i class="bi bi-arrow-right-short"></i>
                        Added more visual elements indicating contracts activity on ledger transactions and assets.
                        </li>
                    </ul>
                </div>
            </slide>
            <slide key="5">
                <div class="card-version w-100">
                    <b class="text-muted">Version 3.15</b>
                    <ul class="small list-unstyled">
                        <li><i class="bi bi-arrow-right-short"></i>Enhance error handling by implementing reliable error messages for assets and accounts</li>
                        <li><i class="bi bi-arrow-right-short"></i>Accounts labels made more visible.</li>
                        <li><i class="bi bi-arrow-right-short"></i>Improve search bar design and searching results.</li>
                    </ul>
                </div>
            </slide>
            <slide key="4">
                <div class="card-version w-100">
                    <b class="text-muted">Version 3.14</b>
                    <ul class="small list-unstyled">
                        <li><i class="bi bi-arrow-right-short"></i>Impove UI elements.</li>
                        <li><i class="bi bi-arrow-right-short"></i>Added dark mode.</li>
                        <li><i class="bi bi-arrow-right-short"></i>Added upload form for contract source codes.</li>
                        <li><i class="bi bi-arrow-right-short"></i>Removed FutureNet from the networks list.</li>
                    </ul>
                </div>
            </slide>
            <slide key="3">
                <div class="card-version w-100">
                    <b class="text-muted">Version 3.13</b>
                    <ul class="small list-unstyled">
                        <li><i class="bi bi-arrow-right-short"></i>Improve homepage charts for easier navigation</li>
                        <li><i class="bi bi-arrow-right-short"></i>Improve assets syncronization and metrics data</li>
                    </ul>
                </div>
            </slide>
            <slide key="2">
                <div class="card-version w-100">
                    <b class="text-muted">Version 3.12</b>
                    <ul class="small list-unstyled">
                        <li><i class="bi bi-arrow-right-short"></i>Add order book stream for market assets.</li>
                        <li><i class="bi bi-arrow-right-short"></i>Add stream for latest trades for market assets.</li>
                        <li><i class="bi bi-arrow-right-short"></i>Add new chart for market assets trades for multiple timeframes.</li>
                    </ul>
                </div>
            </slide>
            <slide key="1">
                <div class="card-version w-100">
                    <b class="text-muted">Version 3.11</b>
                    <ul class="small list-unstyled">
                        <li><i class="bi bi-arrow-right-short"></i>Add market section with market assets XLM pairs.</li>
                    </ul>
                </div>
            </slide>
            <template #addons>
              <navigation />
            </template>
        </carousel>
    </div>
  </div>
</template>

<script>
import { defineComponent, computed, onUnmounted } from "vue";
import { useStore } from "vuex";
import Search from "@/components/Search.vue";
import IndexPageStats from "@/components/stats/IndexPageStats.vue";
import FrontPageChart from "@/components/charts/FrontPageChart.vue";
import LedgerSummary from "@/components/summaries/LedgerSummary.vue";
import { Carousel, Slide, Navigation } from 'vue3-carousel'
import 'vue3-carousel/dist/carousel.css'

export default defineComponent({
  name: "Index",
  components: {
    Search,
    IndexPageStats,
    LedgerSummary,
    FrontPageChart,
    Carousel,
    Slide,
    Navigation
  },
  setup() {
    const store = useStore();
    store.dispatch("ledgers/startStreaming", 5);
    onUnmounted(() => store.dispatch("ledgers/stopStreaming"));

    const darkMode = computed(() => store.getters["settings/getThemeColor"]);

    const chartInfo =
      window.screen.width <= 768
        ? {
            margins: { left: "11.2%", top: "13%", width: "92%", height: "73%" },
            class: "d-md-none",
          }
        : {
            margins: { left: "7.5%", top: "6%", width: "92%", height: "73%" },
            class: "d-none d-md-block",
          };
    return { chartInfo, darkMode};
  },
});
</script>

    
